
import { defineComponent } from "vue";
import 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker';
// eslint-disable-next-line no-unused-vars
import {Alert} from "@/models/Homefeed/api.model";
import TheAlert from "@/components/UIComponents/HomefeedComponents/TheAlert.vue";
import ThePromoEvent from "@/components/UIComponents/HomefeedComponents/ThePromoEvent.vue";
import TheFaqHome from "@/components/UIComponents/HomefeedComponents/TheFaqHome.vue";
import TheImage from "@/components/UIComponents/HomefeedComponents/TheImage.vue";
import TheFile from "@/components/UIComponents/HomefeedComponents/TheFile.vue";
import Loader from "@/components/UIComponents/Loader.vue";
import EventResult from "@/components/UIComponents/PromocodeComponents/EventResult.vue";
import ErrorResult from "@/components/UIComponents/HomefeedComponents/ErrorResult.vue";

export default defineComponent({
  name: 'TheHomefeed',
  components: {
    TheAlert,
    ThePromoEvent,
    TheFaqHome,
    TheImage,
    TheFile,
    Loader,
    EventResult,
    ErrorResult
  },
  data: function () {
    return {
      mainTitle: 'Správa Homefeedu',
      tabs: [
        {
          name: 'Alert',
          order: 1
        },
        {
          name: 'Promo akce',
          order: 2
        },
        {
          name: 'Tipy a triky',
          order: 3
        },
        {
          name: 'Obrázky',
          order: 4
        },
        {
          name: 'Soubory',
          order: 5
        },
      ],
      activeTab: 1,
      promocodesModulConfig: null,
      loading: true,
      error: false,
      errorMsg: '',
      values: ['', ''],
      errorMessage: ['', ''],
      eventTitle: '',
      eventDescription: '',
      validity: null,
      validityTest: 'pending',
      checkMsg: ''
    };
  },
  methods: {
    setLoading(loading: boolean) {
      this.loading = loading;
    },
    startLoading: async function() {
      this.setLoading(true);
    },
    finishLoading: async function() {
      this.setLoading(false);
    },
    resetEvent() {
      this.finishLoading();
      this.eventTitle = '';
      this.eventDescription = '';
      this.error = false;
      this.errorMsg = '';
    },
    invokeError(message: string) {
      this.finishLoading();
      this.error = true;
      this.errorMsg = message;
      window.scrollTo(0,0);
    },
    invokeSuccess(title: string, message: string) {
      this.eventTitle = title;
      this.eventDescription = message;
      window.scrollTo(0,0);
    },
  }
});
