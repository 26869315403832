<template lang="html">
  <div v-if="!loading && error" class="content" @click="$emit('reset-event')">
    <div class="results">
      <div class="group">
        <div class="box">
          <div class="wrap error">
            <h2 class="error">Error:</h2>
            <p class="errordetail" v-if="description !== ''">
              {{ description }}
            </p>
            <p class="errordetail" v-else>No detailed error message.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [ "reset-event" ],
  props: ["error", "loading", "description"],
  name: "ErrorResult"
}
</script>


<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_homefeed.scss";
@import "@/assets/styles/_results.scss";
</style>
