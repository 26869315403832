
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import {PromoEvent, PromoEvents} from "@/models/Homefeed/api.model";
import HomefeedModelConfig from "@/models/Homefeed/config.model";

export default defineComponent({
  name: "TheEventListItem",
  emits: [
    'move-promo-event',
    'remove-promo-event',
    'edit-promo-event',
    'invoke-error',
    'invoke-success',
  ],
  props: {
    promoEvent: Object,
    isRearranging: Boolean,
    listLength: Number
  },
  data() {
    return {
      visible: false,
      homefeedModulConfig: null,
      promoEventObject: this.promoEvent
    };
  },
  created: async function() {
    this.homefeedModulConfig = await HomefeedModelConfig.getConfiguration();
    this.promoEventObject = this.promoEvent as PromoEvent;
  },
  methods: {
    showDetail() {
      this.visible = this.visible !== true;
    },
    getPromoEventTranslates(): PromoEvents[]
    {
      const promoEventObject = this.promoEventObject as PromoEvent;

      if (promoEventObject.page_content.length > 0) {
        return promoEventObject.page_content;
      }
      return null;
    },
    getLanguages(): string[] {
      const translates = this.getPromoEventTranslates();

      if (translates === null) {
        return null;
      }

      const langs = new Array<string>();
      translates.forEach(function(translate) {
        langs.push(translate.lang);
      });
      return langs;
    },
    editPromoEvent(promoEvent: PromoEvent) {
      this.$emit(
          'edit-promo-event',
          promoEvent
      );
    },
    removePromoEvent(promoEvent: PromoEvent) {
      if (!confirm("Opravdu chcete deaktivovat promo akci?")) {
        return;
      }

      this.$emit(
          'remove-promo-event',
          promoEvent.id
      );
    },
    movePromoEvent(promoEvent: PromoEvent, direction: number) {
      this.$emit(
          'move-promo-event',
          promoEvent, direction
      )
    }
  }
});
