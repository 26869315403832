
import { defineComponent } from "vue";

// eslint-disable-next-line no-unused-vars
import {Faq} from "@/models/Homefeed/api.model";
import TheFaqListItem from "@/components/UIComponents/HomefeedComponents/Faq/TheFaqListItem.vue";

export default defineComponent({
  // eslint-disable-next-line vue/no-unused-components
  components: {TheFaqListItem},
  emits: [
    'move-faq',
    'refresh-faqs',
    'start-loading',
    'edit-faq',
    'invoke-success',
    'invoke-error',
  ],
  name: "TheFaqList",
  props: [
    'faqs',
    'isRearranging'
  ],
  methods: {
    moveFaq(faq: Faq, direction: number) {
      this.$emit(
          'move-faq',
          faq, direction
      )
    },
    removeFaqFromList() {
      this.$emit(
          'start-loading'
      );
      this.$emit(
          'refresh-faqs'
      );
    },
    editFaq(value: Faq) {
      this.$emit(
          'edit-faq',
          value
      );
    },
    invokeError(message: string) {
      this.$emit(
          'invoke-error',
          message
      );
    },
    invokeSuccess(title: string, message: string) {
      this.$emit(
          'invoke-success',
          title,
          message
      );
    },
  }
});
