
import {defineComponent} from "vue";
// eslint-disable-next-line no-unused-vars
import {Faq, Faqs, PromoEvent} from "@/models/Homefeed/api.model";
import HomefeedModelConfig from "@/models/Homefeed/config.model";
import TheLanguage from "@/components/UIComponents/HomefeedComponents/Alert/TheLanguage.vue";
import TheFaq from "@/components/UIComponents/HomefeedComponents/Faq/TheFaq.vue";
import TheFaqList from "@/components/UIComponents/HomefeedComponents/Faq/TheFaqList.vue";

export default defineComponent({
  components: {TheFaqList, TheFaq, TheLanguage},
  emits: [
    'set-loading',
    'invoke-success',
    'invoke-error',
    'reset-event'
  ],
  name: "TheFaqHome",
  data: function () {
    return {
      homefeedModulConfig: null,
      faqs: Array<Faq>(),
      faqId: null,
      faqTitle: null,
      faqOrder: null,
      faqTranslates: Array<Faqs>(),
      languages: Array<string>(),
      isRearranging: false,
    };
  },
  computed: {
    getSortedArray() : Array<Faq> {
      return this.sortArray();
    }
  },
  created: async function () {
    this.homefeedModulConfig = await HomefeedModelConfig.getConfiguration();
    await this.fetchFaqs();
  },
  methods: {
    addNewFaq(action: string, language: string) {
      if (action === 'new') {
        this.faqTranslates.push({
          lang: language,
          page_title: '',
          page_image_url: '',
          page_content_url: ''
        } as Faqs);
      } else {
        if (this.faqTranslates.length > 0) {
          this.faqTranslates = this.faqTranslates.filter(obj => obj.lang !== language);
        }
      }
    },
    getFaqByLang(lang: string) {
      let itemIndex = this.faqTranslates.findIndex(item => item.lang === lang);
      return this.faqTranslates[itemIndex];
    },
    setImageContent(lang: string, content: string, extension: string) {
      const faq = this.getFaqByLang(lang);
      faq.page_image = {
        content: content,
        extension: extension
      };
    },
    setPageContent(lang: string, content: string, extension: string) {
      const faq = this.getFaqByLang(lang);
      faq.page_content = {
        content: content,
        extension: extension
      };
    },
    setTitle(lang: string, title: string) {
      const promoEvent = this.getFaqByLang(lang);
      promoEvent.page_title = title;
    },
    startLoading: async function() {
      this.$emit('set-loading', true);
    },
    finishLoading: async function() {
      this.$emit('set-loading', false);
    },
    fetchFaqs: async function () {
      await this.refreshFaqs();
    },
    refreshFaqs: async function() {
      this.$http.get<Faq[]>(this.homefeedModulConfig.apiBaseUrl + `homefeed/faqs`)
          .then((response) => {
            if (response.status !== 200) {
              this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při načítání tipu a triku!");
              return;
            }
            this.faqs = response.data;
            this.finishLoading();
          });
    },
    saveFaq() {
      if (this.faqId === null) {
        const newFaq = this.prepareFaqModel(null);

        this.$http.post(
            this.homefeedModulConfig.apiBaseUrl + `homefeed/faqs`,
            newFaq
        )
            .then((response) => {
              if (response.status !== 200) {
                this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání tipu a triku: " + response.statusText);
              }
              this.faqs.unshift(response.data as Faq);
              this.refreshFaqForm();
              this.refreshFaqs();
              this.$emit("invoke-success", 'Hotovo!', 'Tip a trik byl úspěšně uložen.');
            })
            .catch((error) => {
              this.$emit("invoke-error", "CHYBA při ukládání promo akce: " + error);
            });
      } else {
        const editFaq = this.prepareFaqModel(this.faqId);

        this.$http.put(
            this.homefeedModulConfig.apiBaseUrl + `homefeed/faqs/` + this.faqId,
            editFaq
        )
            .then((response) => {
              if (response.status !== 200) {
                this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání tipu a triku: " + response.statusText);
              }
              this.refreshFaqForm();
              this.refreshFaqs();
              this.$emit("invoke-success", 'Hotovo!', 'Tip a trik byl úspěšně uložen.');
            })
            .catch((error) => {
              this.$emit("invoke-error", "CHYBA při ukládání promo akce: " + error);
            });
      }
    },
    rearrange() {
      this.isRearranging = true;
    },
    cancelRearrange() {
      this.isRearranging = false;
      this.refreshFaqs();
    },
    saveOrderedFaqs() {
      const list = this.getOrderedFaqs();

      this.$http.post(
          this.homefeedModulConfig.apiBaseUrl + `homefeed/faqs/ordered`,
          list
      ).then((response)=> {
        if (response.status !== 200) {
          this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání pořadí faqs: " + response.statusText)
        }
        console.log(response.data);
        this.cancelRearrange();
      }).catch((error) => {
        console.log(error);
      });
    },
    getOrderedFaqs() {
      let map = new Map();
      this.faqs.forEach((x) => map.set(x.id, x.order));
      return Object.fromEntries(map.entries());
    },
    moveFaq(faq: Faq, direction: number) {
      let index = this.faqs.findIndex((x) => x.order === faq.order);

      if ((direction == -1 && index == 0)
          || (index == this.faqs.length - 1 && direction == 1)) { return; }

      let newIndex = index + direction;

      let temp = this.faqs[index].order;
      this.faqs[index].order = this.faqs[newIndex].order;
      this.faqs[newIndex].order = temp;
    },
    prepareFaqFormFromModel(faq: Faq) {
      const languages = [] as string[];

      faq.page_content.forEach((translate) => {
        languages.push(translate.lang);
      });

      this.faqId = faq.id;
      this.faqTitle = faq.title;
      this.faqOrder = faq.order;
      this.languages = languages;
      this.faqTranslates = faq.page_content;
    },
    refreshFaqForm() {
      this.faqId = null;
      this.faqTitle = '';
      this.faqOrder = null;
      this.faqTranslates = new Array<Faqs>();
      this.languages = new Array<string>();
    },
    prepareFaqModel(faqId: string | null): Faq {
      if (faqId === null) {
        return {
          title: this.faqTitle,
          order: this.faqOrder,
          page_content: this.faqTranslates,
        } as Faq;
      } else {
        return {
          id: faqId,
          title: this.faqTitle,
          order: this.faqOrder,
          page_content: this.faqTranslates
        } as Faq;
      }
    },
    editFaq(editFaq: Faq) {
      this.prepareFaqFormFromModel(editFaq);
      window.scrollTo(0,0);
    },
    removePromoEvent(index: number) {
      this.faqs.splice(index, 1)
    },
    sortArray() {
      return this.faqs.sort((a: Faq, b: Faq) => a.order - b.order);
    },
    invokeError(message: string) {
      this.$emit(
          'invoke-error',
          message
      );
    },
    invokeSuccess(title: string, message: string) {
      this.$emit(
          'invoke-success',
          title,
          message
      );
    },
  }
});
