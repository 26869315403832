
import {defineComponent} from "vue";
// eslint-disable-next-line no-unused-vars
import {Image} from "@/models/Homefeed/api.model";
import HomefeedModelConfig from "@/models/Homefeed/config.model";

export default defineComponent({
  emits: [
    'set-faq',
    'invoke-error',
    'invoke-success',
    'set-image-content',
    'set-title',
    'set-page-content'
  ],
  props: {
    faq: {
      type: Object
    }
  },
  name: "TheFaq",
  data() {
    return {
      homefeedModulConfig: null,
      file: '',
      faqTranslate: this.faq,
    };
  },
  created: async function () {
    this.homefeedModulConfig = await HomefeedModelConfig.getConfiguration();
  },
  methods: {
    selectFile(event: any) {
      const files = event.target.files;
      const file = files[0];

      if (files && file) {
        this.file = file;

        const reader = new FileReader();
        reader.onload = this.handleFile.bind(this);
        reader.readAsText(file, "utf-8");
      }
    },
    handleFile(event: any) {
      const binaryString = event.target.result;
      this.file = '';
      this.$emit("set-page-content", this.faqTranslate.lang, binaryString, "json");
    },
    selectContentImageFile(event: any) {
      const files = event.target.files;
      const file = files[0];

      if (files && file) {
        this.file = file;

        const reader = new FileReader();
        reader.onload = this.handleContentImageFile.bind(this);
        reader.readAsBinaryString(file);
      }
    },
    handleContentImageFile(event: any) {
      const binaryString = event.target.result;

      const file = this.file as any;

      let fileExtension = 'unknown';
      if (file.type === "image/png") {
        fileExtension = "png";
      }
      if (file.type === "image/jpg") {
        fileExtension = "jpg";
      }
      if (file.type === "image/jpeg") {
        fileExtension = "jpeg";
      }
      if (file.type === "image/gif") {
        fileExtension = "gif";
      }

      this.file = '';
      this.$emit("set-image-content", this.faqTranslate.lang, btoa(binaryString), fileExtension);
    }
  }
});
