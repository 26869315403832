
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import {Faq, Faqs} from "@/models/Homefeed/api.model";
import HomefeedModelConfig from "@/models/Homefeed/config.model";

export default defineComponent({
  name: "TheFaqListItem",
  emits: [
    'move-faq',
    'remove-faq',
    'edit-faq',
    'invoke-error',
    'invoke-success',
  ],
  props: {
    faq: Object,
    isRearranging: Boolean,
    listLength: Number
  },
  data() {
    return {
      visible: false,
      homefeedModulConfig: null,
      faqObject: this.faq
    };
  },
  created: async function() {
    this.homefeedModulConfig = await HomefeedModelConfig.getConfiguration();
    this.faqObject = this.faq as Faqs;
  },
  methods: {
    showDetail() {
      this.visible = this.visible !== true;
    },
    getFaqTranslates(): Faqs[]
    {
      const faqObject = this.faqObject as Faq;

      if (faqObject.page_content.length > 0) {
        return faqObject.page_content;
      }
      return null;
    },
    getLanguages(): string[] {
      const translates = this.getFaqTranslates();

      if (translates === null) {
        return null;
      }

      const langs = new Array<string>();
      translates.forEach(function(translate) {
        langs.push(translate.lang);
      });
      return langs;
    },
    editFaq(faq: Faq) {
      this.$emit(
          'edit-faq',
          faq
      );
    },
    removeFaq(faq: Faq) {
      if (!confirm("Opravdu chcete deaktivovat upozornění?")) {
        return;
      }

      this.$http.delete(this.homefeedModulConfig.apiBaseUrl + `homefeed/faqs/` + faq.id)
          .then((response) => {
            if (response.status === 204) {
              this.$emit(
                  'remove-faq'
              );
              this.$emit(
                  'invoke-success',
                  'Hotovo!',
                  'Tip a trik byl smazána!'
              );
            } else {
              this.$emit(
                  'invoke-error',
                  "CHYBA při ukládání změny: " + response.statusText
              );
            }
          })
          .catch((error) => {
            this.$emit(
                'invoke-error',
                "CHYBA při ukládání změny: " + error
            );
          });
    },
    moveFaq(faq: Faq, direction: number) {
      this.$emit(
          'move-faq',
          faq,
          direction
      );
    }
  }
});
