
import { defineComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import {Alert, AlertMessage} from "@/models/Homefeed/api.model";
import HomefeedModelConfig from "@/models/Homefeed/config.model";

export default defineComponent({
  name: "TheAlertMessageListItem",
  emits: [
    'remove-alert',
    'edit-alert',
    'invoke-error',
    'invoke-success',
  ],
  props: {
    alertItem: Object
  },
  data() {
    return {
      homefeedModulConfig: null,
      alertObject: this.alertItem
    };
  },
  created: async function() {
    this.homefeedModulConfig = await HomefeedModelConfig.getConfiguration();
    this.alertObject = this.alertItem as Alert;
  },
  methods: {
    getAlertMessages(): AlertMessage[]
    {
      const alertObject = this.alertObject as Alert;

      if (alertObject.messages.length > 0) {
        return alertObject.messages;
      }
      return null;
    },
    getLanguages(): string[] {
      const messages = this.getAlertMessages();

      if (messages === null) {
        return null;
      }

      const langs = new Array<string>();
      messages.forEach(function(message) {
        langs.push(message.lang);
      });
      return langs;
    },
    editAlert(alert: Alert) {
      this.$emit(
          'edit-alert',
          alert
      );
    },
    removeAlert(alert: Alert) {
      if (!confirm("Opravdu chcete deaktivovat upozornění?")) {
        return;
      }

      this.$http.delete(this.homefeedModulConfig.apiBaseUrl + `homefeed/alerts/` + alert.id)
          .then((response) => {
            if (response.status === 204) {
              this.$emit(
                  'remove-alert'
              );
              this.$emit(
                  'invoke-success',
                  'Hotovo!',
                  'Upozornění bylo deaktivováno!'
              );
            } else {
              this.$emit(
                  'invoke-error',
                  "CHYBA při ukládání změny: " + response.statusText
              );
            }
          })
          .catch((error) => {
            this.$emit(
                'invoke-error',
                "CHYBA při ukládání změny: " + error
            );
          });
    }
  }
});
