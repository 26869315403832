
import {defineComponent} from "vue";
// eslint-disable-next-line no-unused-vars
import {Alert, AlertMessage} from "@/models/Homefeed/api.model";
import HomefeedModelConfig from "@/models/Homefeed/config.model";
import $ from "jquery";
import TheLanguage from "@/components/UIComponents/HomefeedComponents/Alert/TheLanguage.vue";
import ThePlatform from "@/components/UIComponents/HomefeedComponents/Alert/ThePlatform.vue";
import TheAlertMessage from "@/components/UIComponents/HomefeedComponents/Alert/TheAlertMessage.vue";
import TheAlertMessageList from "@/components/UIComponents/HomefeedComponents/Alert/TheAlertMessageList.vue";
import moment from 'moment'

export default defineComponent({
  components: {TheAlertMessageList, ThePlatform, TheLanguage, TheAlertMessage},
  emits: [
      'set-loading',
      'invoke-success',
      'invoke-error',
      'reset-event'
  ],
  name: "TheAlert",
  data: function () {
    return {
      homefeedModulConfig: null,
      alerts: Array<Alert>(),
      alertId: null,
      messages: Array<AlertMessage>(),
      platforms: Array<string>(),
      languages: Array<string>(),
      dateFrom: '',
      dateTo: '',
      timeFrom: '00:00:00',
      timeTo: '23:59:59'
    };
  },
  mounted() {
    this.refreshDatePickers();
  },
  renderTriggered() {
    this.refreshDatePickers();
  },
  created: async function () {
    this.homefeedModulConfig = await HomefeedModelConfig.getConfiguration();
    await this.fetchAlerts();
  },
  methods: {
    addPlatform(platform: string) {
      if (this.platforms.some(obj => obj === platform)) {
        this.platforms = this.platforms.filter(obj => obj !== platform);
      } else {
        this.platforms.push(platform);
      }
    },
    addNewMessage(action: string, language: string) {
      if (action === 'new') {
        this.messages.push({ lang: language, text: "" } as AlertMessage);
      } else {
        if (this.messages.length > 0) {
          this.messages = this.messages.filter(obj => obj.lang !== language);
        }
      }
    },
    setAlertMessage(message: AlertMessage, text: string) {
      let itemIndex = this.messages.findIndex(item => item.lang === message.lang);
      this.messages[itemIndex].text = text;
    },
    refreshDatePickers() {
      const self = this;
      $(function() {
        $("#dateFrom").datepicker({
          dateFormat: "yy-mm-dd",
          onSelect: function(dateText: string) {
            $('#dateFrom').attr('value', dateText);
            self.setDateFrom(dateText);
          }
        });
        $("#dateTo").datepicker({
          dateFormat: "yy-mm-dd",
          onSelect: function(dateText: string) {
            $('#dateTo').attr('value', dateText);
            self.setDateTo(dateText);
          }
        });
      });
    },
    startLoading: async function() {
      this.$emit('set-loading', true);
    },
    finishLoading: async function() {
      this.$emit('set-loading', false);
    },
    fetchAlerts: async function () {
      await this.refreshAlerts();
    },
    refreshAlerts: async function() {
      this.$http.get<Alert[]>(this.homefeedModulConfig.apiBaseUrl + `homefeed/alerts`)
          .then((response) => {
            if (response.status !== 200) {
              this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při načítání alertů!");
              return;
            }
            this.alerts = response.data;
            this.finishLoading();
          });
    },
    saveAlert() {
      this.startLoading();

      let validated = true;
      if (this.messages.length === 0) {
        validated = false;
        this.$emit("invoke-error", "CHYBA: Je potřeba zvolit pro vytvoření zprávy!");
        return;
      }

      if (this.platforms.length === 0) {
        validated = false;
        this.$emit("invoke-error", "CHYBA: Je potřeba zvolit platformy, pro kterou je upozorňovací zpráva určena!");
        return;
      }

      if (this.dateFrom === "" || this.dateTo === "") {
        validated = false;
        this.$emit("invoke-error", "CHYBA: Je potřeba zvolit datum platnosti upozorňovací zprávy!");
        return;
      }

      if (validated) {
        if (this.alertId === null) {
          const newAlert = this.prepareAlertModel(null);

          this.$http.post(
              this.homefeedModulConfig.apiBaseUrl + `homefeed/alerts`,
              newAlert
          )
              .then((response) => {
                if (response.status !== 200) {
                  this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání alertu: " + response.statusText);
                }
                this.alerts.unshift(response.data as Alert);
                this.refreshAlertForm();
                this.finishLoading();
                this.$emit("invoke-success", 'Hotovo!', 'Alert byl úspěšně uložen.');
              })
              .catch((error) => {
                this.finishLoading();
                this.$emit("invoke-error", "CHYBA při ukládání alertu: " + error);
              });
        } else {
          const editAlert = this.prepareAlertModel(this.alertId);

          this.$http.put(
              this.homefeedModulConfig.apiBaseUrl + `homefeed/alerts/` + this.alertId,
              editAlert
          )
              .then((response) => {
                if (response.status !== 200) {
                  this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání alertu: " + response.statusText);
                }
                this.refreshAlertForm();
                this.finishLoading();
                this.$emit("invoke-success", 'Hotovo!', 'Alert byl úspěšně uložen.');
              })
              .catch((error) => {
                this.$emit("invoke-error", "CHYBA při ukládání alertu: " + error);
              });
        }
      }
    },
    prepareAlertFormFromModel(alert: any) {
      const languages = [] as string[];
      if (alert.messages.length > 0) {
        alert.messages.forEach((item: AlertMessage) => {
          languages.push(item.lang);
        });
      }

      this.alertId = alert.id;
      this.messages = alert.messages;
      this.platforms = alert.platforms;
      this.languages = languages;
      this.dateFrom = moment(alert.from, 'YYYY-MM-DDTHH:mm:ssK').format('YYYY-MM-DD');
      this.dateTo = moment(alert.to, 'YYYY-MM-DDTHH:mm:ssK').format('YYYY-MM-DD');
      this.timeFrom = moment(alert.from, 'YYYY-MM-DDTHH:mm:ssK').format('HH:mm:ss');
      this.timeTo = moment(alert.to, 'YYYY-MM-DDTHH:mm:ssK').format('HH:mm:ss');
    },
    refreshAlertForm() {
      this.alertId = null;
      this.dateFrom = '';
      this.dateTo = '';
      this.messages = new Array<AlertMessage>();
      this.platforms = new Array<string>();
      this.languages = new Array<string>();
    },
    prepareAlertModel(alertId: string | null): Alert {
      if (alertId === null) {
        return {
          from: this.dateFrom + "T" + this.timeFrom,
          to: this.dateTo + "T" + this.timeTo,
          platforms: this.platforms,
          messages: this.messages
        } as Alert;
      } else {
        return {
          id: alertId,
          from: this.dateFrom + "T" + this.timeFrom,
          to: this.dateTo + "T" + this.timeTo,
          platforms: this.platforms,
          messages: this.messages
        } as Alert;
      }
    },
    editAlert(editAlert: Alert) {
      this.prepareAlertFormFromModel(editAlert);
      window.scrollTo(0,0);
    },
    removeAlert(index: number) {
      this.alerts.splice(index, 1)
    },
    setDateFrom(value: string) {
      this.dateFrom = value;
    },
    setDateTo(value: string) {
      this.dateTo = value;
    },
    invokeError(message: string) {
      this.$emit(
          'invoke-error',
          message
      );
    },
    invokeSuccess(title: string, message: string) {
      this.$emit(
          'invoke-success',
          title,
          message
      );
    },
  }
});
