
import { defineComponent } from "vue";

// eslint-disable-next-line no-unused-vars
import {Alert} from "@/models/Homefeed/api.model";
import TheAlertMessageListItem from "@/components/UIComponents/HomefeedComponents/Alert/TheAlertMessageListItem.vue";

export default defineComponent({
  // eslint-disable-next-line vue/no-unused-components
  components: {TheAlertMessageListItem},
  emits: [
    'refresh-alerts',
    'start-loading',
    'edit-alert',
    'invoke-success',
    'invoke-error',
  ],
  name: "TheAlertMessageList",
  props: [
    'alerts'
  ],
  methods: {
    removeAlertFromList() {
      this.$emit(
          'start-loading'
      );
      this.$emit(
          'refresh-alerts'
      );
    },
    editAlert(value: Alert) {
      this.$emit(
          'edit-alert',
          value
      );
    },
    invokeError(message: string) {
      this.$emit(
          'invoke-error',
          message
      );
    },
    invokeSuccess(title: string, message: string) {
      this.$emit(
          'invoke-success',
          title,
          message
      );
    },
  }
});
