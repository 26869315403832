
import {defineComponent} from "vue";
// eslint-disable-next-line no-unused-vars
import {AlertMessage, PromoEvent, PromoEvents} from "@/models/Homefeed/api.model";
import HomefeedModelConfig from "@/models/Homefeed/config.model";
import TheLanguage from "@/components/UIComponents/HomefeedComponents/Alert/TheLanguage.vue";
import TheEvent from "@/components/UIComponents/HomefeedComponents/PromoEvent/TheEvent.vue";
import TheEventList from "@/components/UIComponents/HomefeedComponents/PromoEvent/TheEventList.vue";

export default defineComponent({
  components: {TheEventList, TheEvent, TheLanguage},
  emits: [
    'set-loading',
    'invoke-success',
    'invoke-error',
    'reset-event'
  ],
  name: "ThePromoEvent",
  data: function () {
    return {
      homefeedModulConfig: null,
      promoEvents: Array<PromoEvent>(),
      promoEventId: null,
      promoEventTitle: null,
      promoEventOrder: null,
      promoEventTranslates: Array<PromoEvents>(),
      isRearranging: false,
      platforms: Array<string>(),
      languages: Array<string>(),
      dateFrom: '',
      dateTo: '',
      timeFrom: '00:00:00',
      timeTo: '23:59:59'
    };
  },
  computed: {
    getSortedArray() : Array<PromoEvent> {
      return this.sortArray();
    }
  },
  created: async function () {
    this.homefeedModulConfig = await HomefeedModelConfig.getConfiguration();
    await this.fetchPromoEvents();
  },
  methods: {
    submit(event: any) {
      event.preventDefault();
      console.log("Test");
    },
    addNewPromoEvent(action: string, language: string) {
      if (action === 'new') {
        this.promoEventTranslates.push({
          lang: language,
          page_title: '',
          page_image_url: '',
          page_content_url: ''
        } as PromoEvents);
      } else {
        if (this.promoEventTranslates.length > 0) {
          this.promoEventTranslates = this.promoEventTranslates.filter(obj => obj.lang !== language);
        }
      }
    },
    getPromoEventByLang(lang: string) {
      let itemIndex = this.promoEventTranslates.findIndex(item => item.lang === lang);
      return this.promoEventTranslates[itemIndex];
    },
    setImageContent(lang: string, content: string, extension: string) {
      const promoEvent = this.getPromoEventByLang(lang);
      promoEvent.page_image = {
        content: content,
        extension: extension
      };
    },
    setPageContentImageUrl(lang: string, url: string) {
      const promoEvent = this.getPromoEventByLang(lang);
      promoEvent.page_content_image_url = url;
    },
    setTitle(lang: string, title: string) {
      const promoEvent = this.getPromoEventByLang(lang);
      promoEvent.page_title = title;
    },
    setWeb(lang: string, web: string) {
      const promoEvent = this.getPromoEventByLang(lang);
      promoEvent.page_content_web = web;
    },
    setDescription(lang: string, description: string) {
      const promoEvent = this.getPromoEventByLang(lang);
      promoEvent.page_content_description = description;
    },
    setHowToWorks(lang: string, howToWorks: string) {
      const promoEvent = this.getPromoEventByLang(lang);
      promoEvent.page_content_how_to_works = howToWorks;
    },
    setDate(lang: string, date: string) {
      const promoEvent = this.getPromoEventByLang(lang);
      promoEvent.page_content_date = date;
    },
    generatePageContent(promoEvent: PromoEvents) {
      let promoEventTitleTranslates;
      if (promoEvent.lang === "cs") {
        promoEventTitleTranslates = {
          howToWorks: "Podmínky",
          date: "Platnost",
          web: "Přejít do e-shopu"
        };
      }
      if (promoEvent.lang === "sk") {
        promoEventTitleTranslates = {
          howToWorks: "Podmienky",
          date: "Platnosť",
          web: "Prejsť do e-shopu"
        };
      }
      if (promoEvent.lang === "hu") {
        promoEventTitleTranslates = {
          howToWorks: "Feltételek",
          date: "Érvényesség",
          web: "Tovább a webáruházba"
        };
      }
      if (promoEvent.lang === "ro") {
        promoEventTitleTranslates = {
          howToWorks: "Condiții",
          date: "Valabilitate",
          web: "Magazin electronic"
        };
      }

      const promoEventContent = [
        {
          "type": "message",
          "text": promoEvent.page_content_description
        },
        {
          "type": "image",
          "image_url": promoEvent.page_content_image_url
        },
        {
          "type": "text",
          "text": promoEventTitleTranslates.howToWorks,
          "caption": promoEvent.page_content_how_to_works
        },
        {
          "type": "text",
          "text": promoEventTitleTranslates.date,
          "caption": promoEvent.page_content_date
        }
      ];

      if (promoEvent.page_content_web !== undefined && promoEvent.page_content_web !== null && promoEvent.page_content_web !== '') {
        const page_content_web = {
          "type": "action",
          "button_type": "gray",
          "text": promoEventTitleTranslates.web,
          "url": promoEvent.page_content_web
        };
        promoEventContent.push(page_content_web);
      }

      promoEvent.page_content = {
        content: JSON.stringify(promoEventContent),
        extension: "json"
      };
    },
    startLoading: async function() {
      this.$emit('set-loading', true);
    },
    finishLoading: async function() {
      this.$emit('set-loading', false);
    },
    fetchPromoEvents: async function () {
      await this.refreshPromoEvents();
    },
    removePromoEvent: async function(id: string) {
      await this.$http.delete(this.homefeedModulConfig.apiBaseUrl + `homefeed/promo-events/` + id)
          .then((response) => {
            if (response.status === 204) {
              this.$emit(
                  'invoke-success',
                  'Hotovo!',
                  'Promo akce byla smazána!'
              );
              const index = this.promoEvents.findIndex(pe => pe.id === id);
              this.promoEvents.splice(index, 1);
              this.refreshPromoEvents();
              this.refreshPromoEventForm();
              this.refreshPromoEvents();
              this.finishLoading();
            } else {
              this.$emit(
                  'invoke-error',
                  "CHYBA při ukládání změny: " + response.statusText
              );
            }
          })
          .catch((error) => {
            this.$emit(
                'invoke-error',
                "CHYBA při ukládání změny: " + error
            );
          });
    },
    refreshPromoEvents: async function() {
      this.$http.get<PromoEvent[]>(this.homefeedModulConfig.apiBaseUrl + `homefeed/promo-events`)
          .then((response) => {
            if (response.status !== 200) {
              this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při načítání promo akcí!");
              return;
            }
            this.promoEvents = response.data;
            this.promoEvents.forEach((promoEvent) => {
              this.fillPromoEventPageContent(promoEvent);
            });
            this.finishLoading();
          });
    },
    savePromoEvent() {
      if (!this.isValid()) {
        this.$emit("invoke-error", "Please fill out all required fields!");
        return;
      }
      
      this.startLoading();

      if (this.promoEventId === null) {
        const newPromoEvent = this.preparePromoEventModel(null);

        this.$http.post(
            this.homefeedModulConfig.apiBaseUrl + `homefeed/promo-events`,
            newPromoEvent
        )
            .then((response) => {
              if (response.status !== 200) {
                this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání promo akce: " + response.statusText);
              }
              this.promoEvents.unshift(response.data as PromoEvent);
              this.refreshPromoEventForm();
              this.refreshPromoEvents();
              this.finishLoading();
              this.$emit("invoke-success", 'Hotovo!', 'Promo akce byla úspěšně uložena.');
            })
            .catch((error) => {
              this.$emit("invoke-error", "CHYBA při ukládání promo akce: " + error);
            });
      } else {
        const editPromoEvent = this.preparePromoEventModel(this.promoEventId);

        this.$http.put(
            this.homefeedModulConfig.apiBaseUrl + `homefeed/promo-events/` + this.promoEventId,
            editPromoEvent
        )
            .then((response) => {
              if (response.status !== 200) {
                this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání promo akce: " + response.statusText);
              }
              this.refreshPromoEventForm();
              this.finishLoading();
              this.$emit("invoke-success", 'Hotovo!', 'Promo akce byla úspěšně uložena.');
            })
            .catch((error) => {
              this.$emit("invoke-error", "CHYBA při ukládání promo akce: " + error);
            });
      }
    },
    rearrangePromoEvents() {
      this.isRearranging = true;
      this.promoEvents.sort((a) => a.order).forEach((item, index) => {
        item.order = index + 1;
      });
    },
    cancelRearrange() {
      this.isRearranging = false;
      this.refreshPromoEvents();
    },
    saveOrderedPromoEvents() {
      const list = this.getOrderedPromoEvents();
      
      this.$http.post(
          this.homefeedModulConfig.apiBaseUrl + `homefeed/promo-events/ordered`,
          list
      ).then((response)=> {
        if (response.status !== 200) {
          this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání pořadí promo akce: " + response.statusText)
        }
        console.log(response.data);
        this.cancelRearrange();
      }).catch((error) => {
        console.log(error);
      });
    },
    getOrderedPromoEvents() {
      let map = new Map();
      this.promoEvents.forEach((x) => map.set(x.id, x.order));
      return Object.fromEntries(map.entries());
    },
    movePromoEvent(promoEvent: PromoEvent, direction: number) {
      let index = this.promoEvents.findIndex((x) => x.order === promoEvent.order);
      
      if ((direction == -1 && index == 0)
          || (index == this.promoEvents.length - 1 && direction == 1)) { return; }

      let newIndex = index + direction;

      let temp = this.promoEvents[index].order;
      this.promoEvents[index].order = this.promoEvents[newIndex].order;
      this.promoEvents[newIndex].order = temp;
    },
    preparePromoEventFormFromModel(promoEvent: PromoEvent) {
      const languages = [] as string[];
      this.fillPromoEventPageContent(promoEvent);

      promoEvent.page_content.forEach((translate) => {
        languages.push(translate.lang);
        console.log(translate);
      });

      this.promoEventOrder = promoEvent.order;
      this.promoEventId = promoEvent.id;
      this.promoEventTitle = promoEvent.title;
      this.languages = languages;
      this.promoEventTranslates = promoEvent.page_content;
    },
    fillPromoEventPageContent(promoEvent: PromoEvent) {
      promoEvent.page_content.forEach((translate) => {
        this.$http.get(this.homefeedModulConfig.apiBaseUrl + `homefeed/contents/?url=` + translate.page_content_url)
            .then((response) => {
              if (response.status !== 200) {
                this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při získávání obsahu promo akce: " + response.statusText);
              }

              let counter = 0;
              response.data.forEach((item: any) => {
                if (counter === 0) {
                  translate.page_content_description = item.text;
                }
                if (counter === 1) {
                  translate.page_content_image_url = item.image_url;
                }
                if (counter === 2) {
                  translate.page_content_how_to_works = item.caption;
                }
                if (counter === 3) {
                  translate.page_content_date = item.caption;
                }
                if (counter === 4) {
                  translate.page_content_web = item.url;
                }

                counter++;
              });
            });
      });
    },
    refreshPromoEventForm() {
      this.promoEventId = null;
      this.promoEventTitle = '';
      this.promoEventOrder = null;
      this.promoEventTranslates = new Array<PromoEvents>();
      this.languages = new Array<string>();
    },
    preparePromoEventModel(promoEventId: string | null): PromoEvent {
      this.promoEventTranslates.forEach((promoEvent) => {
        this.generatePageContent(promoEvent);
      });

      if (promoEventId === null) {
        return {
          title: this.promoEventTitle,
          order: this.promoEventOrder,
          page_content: this.promoEventTranslates
        } as PromoEvent;
      } else {
        return {
          id: promoEventId,
          title: this.promoEventTitle,
          order: this.promoEventOrder,
          page_content: this.promoEventTranslates
        } as PromoEvent;
      }
    },
    editPromoEvent(editPromoEvent: PromoEvent) {
      this.preparePromoEventFormFromModel(editPromoEvent);
      window.scrollTo(0,0);
    },
    sortArray() {
      return this.promoEvents.sort((a: PromoEvent, b: PromoEvent) => a.order - b.order);
    },
    isValid() {
      return this.languages.length != 0 && this.promoEventTitle != null;
    },
    invokeError(message: string) {
      this.$emit(
          'invoke-error',
          message
      );
    },
    invokeSuccess(title: string, message: string) {
      this.$emit(
          'invoke-success',
          title,
          message
      );
    },
  }
});
