import Config from "@/utils/config.service";

export interface Configuration
{
    apiBaseUrl: string
}

export default class HomefeedModelConfig {
    public static async getConfiguration(): Promise<Configuration> {
        const configInstance = Config.getInstance();
        const configuration = await configInstance.getConfiguration();

        return configuration.homefeedModule as Configuration;
    }
}