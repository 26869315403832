
import {defineComponent} from "vue";
// eslint-disable-next-line no-unused-vars
import {Image} from "@/models/Homefeed/api.model";
import HomefeedModelConfig from "@/models/Homefeed/config.model";

export default defineComponent({
  emits: [
      'set-promo-event',
      'invoke-error',
      'invoke-success',
      'set-page-content-image-url',
      'set-image-url',
      'set-title',
      'set-date',
      'set-description',
      'set-how-to-works',
      'set-web'
  ],
  props: {
    promoEvent: {
      type: Object
    }
  },
  name: "TheEvent",
  data() {
    return {
      homefeedModulConfig: null,
      file: '',
      promoEventTranslate: this.promoEvent,
    };
  },
  created: async function () {
    this.homefeedModulConfig = await HomefeedModelConfig.getConfiguration();
  },
  methods: {
    selectFile(event: any) {
      const files = event.target.files;
      const file = files[0];

      if (files && file) {
        this.file = file;

        const reader = new FileReader();
        reader.onload = this.handleFile.bind(this);
        reader.readAsBinaryString(file);
      }
    },
    handleFile(event: any) {
      const binaryString = event.target.result;

      const file = this.file as any;

      let fileExtension = 'unknown';
      if (file.type === "image/png") {
        fileExtension = "png";
      }
      if (file.type === "image/jpg") {
        fileExtension = "jpg";
      }
      if (file.type === "image/jpeg") {
        fileExtension = "jpeg";
      }
      if (file.type === "image/gif") {
        fileExtension = "gif";
      }

      this.file = '';
      this.$emit("set-image-url", this.promoEventTranslate.lang, btoa(binaryString), fileExtension);
    },
    selectContentImageFile(event: any) {
      const files = event.target.files;
      const file = files[0];

      if (files && file) {
        this.file = file;

        const reader = new FileReader();
        reader.onload = this.handleContentImageFile.bind(this);
        reader.readAsBinaryString(file);
      }
    },
    handleContentImageFile(event: any) {
      const binaryString = event.target.result;

      const file = this.file as any;

      let fileExtension = 'unknown';
      if (file.type === "image/png") {
        fileExtension = "png";
      }
      if (file.type === "image/jpg") {
        fileExtension = "jpg";
      }
      if (file.type === "image/jpeg") {
        fileExtension = "jpeg";
      }
      if (file.type === "image/gif") {
        fileExtension = "gif";
      }

      const newFile = {
        content: btoa(binaryString),
        extension: fileExtension
      };

      this.$http.post<Image>(this.homefeedModulConfig.apiBaseUrl + `homefeed/images`, newFile)
          .then((response) => {
            if (response.status !== 200) {
              this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání obrázku: " + response.statusText);
            }
            this.file = '';
            this.$emit("set-page-content-image-url", this.promoEventTranslate.lang, response.data.url);
            this.$emit("invoke-success", 'Hotovo!', 'Obrázek byl úspěšně uložen.');
          })
          .catch((error) => {
            this.$emit("invoke-error", "CHYBA při ukládání obrázku: " + error);
          });
    }
  }
});
