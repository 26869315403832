
import {defineComponent} from "vue";

// eslint-disable-next-line no-unused-vars
import {Image} from "@/models/Homefeed/api.model";
import HomefeedModelConfig from "@/models/Homefeed/config.model";

export default defineComponent({
  emits: [
    'set-loading',
    'invoke-success',
    'invoke-error'
  ],
  name: "TheImage",
  data() {
    return {
      homefeedModulConfig: null,
      fileContent: '',
      fileExtension: '',
      createdFile: '',
    };
  },
  created: async function () {
    this.homefeedModulConfig = await HomefeedModelConfig.getConfiguration();
  },
  methods: {
    uploadFile() {
      const newFile = {
        content: this.fileContent,
        extension: this.fileExtension
      };

      this.$http.post<Image>(this.homefeedModulConfig.apiBaseUrl + `homefeed/images`, newFile)
          .then((response) => {
            if (response.status !== 200) {
              this.$emit("invoke-error", "CHYBA (STATUS: " + response.status + ") při ukládání obrázku: " + response.statusText);
            }
            this.createdFile = response.data.url;
            this.fileContent = '';
            this.fileExtension = '';
            this.$emit("invoke-success", 'Hotovo!', 'Obrázek byl úspěšně uložen.');
          })
          .catch((error) => {
            this.$emit("invoke-error", "CHYBA při ukládání obrázku: " + error);
          });
    },
    selectFile(event: any) {
      const files = event.target.files;
      const file = files[0];

      console.log(file);

      if (files && file) {
        const reader = new FileReader();
        reader.onload =this.handleFile.bind(this);
        reader.readAsBinaryString(file);

        this.fileExtension = 'unknown';
        if (file.type === "image/png") {
          this.fileExtension = "png";
        }
        if (file.type === "image/jpg") {
          this.fileExtension = "jpg";
        }
        if (file.type === "image/jpeg") {
          this.fileExtension = "jpeg";
        }
        if (file.type === "image/gif") {
          this.fileExtension = "gif";
        }
      }
    },
    handleFile(event: any) {
      const binaryString = event.target.result;
      this.fileContent = btoa(binaryString);
    }
  }
});
