
import {defineComponent} from "vue";

export default defineComponent({
  emits: [
      'set-message'
  ],
  props: {
    message: {
      type: Object
    }
  },
  name: "TheAlertMessage",
});
