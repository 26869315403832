
import {defineComponent} from "vue";
export default defineComponent({
  emits: [
      'add-language'
  ],
  updated() {
    this.languages = this.selectedLanguages;
  },
  props: {
    modelValue: String,
    title: String,
    name: String,
    selectedLanguages: Array,
  },
  data() {
    return {
      languages: this.selectedLanguages,
      entities: [
        {
          class: 'cz',
          label: 'cs',
          title: 'CZ'
        },
        {
          class: 'sk',
          label: 'sk',
          title: 'SK'
        },
        {
          class: 'hu',
          label: 'hu',
          title: 'HU'
        },
        {
          class: 'ro',
          label: 'ro',
          title: 'RO'
        }
      ]
    };
  },
  methods: {
    addLanguage(language: string) {
      if (!this.languages.includes(language)) {
        this.languages.push(language);
        this.$emit(
            'add-language',
            'new',
            language
        );
      } else {
        this.languages.forEach( (item, index) => {
          if(item === language) this.languages.splice(index,1);
        });
        this.$emit(
            'add-language',
            'remove',
            language
        );
      }
    },
    getLanguages() {
      return this.languages;
    }
  }
});
