
import { defineComponent } from "vue";

// eslint-disable-next-line no-unused-vars
import {Alert, PromoEvent} from "@/models/Homefeed/api.model";
import TheEventListItem from "@/components/UIComponents/HomefeedComponents/PromoEvent/TheEventListItem.vue";

export default defineComponent({
  // eslint-disable-next-line vue/no-unused-components
  components: {TheEventListItem},
  emits: [
    'move-promo-event',
    'remove-promo-event',
    'start-loading',
    'edit-promo-event',
    'invoke-success',
    'invoke-error',
  ],
  name: "TheEventList",
  props: [
    'promoEvents',
    'isRearranging'
  ],
  methods: {
    movePromoEvent(promoEvent: PromoEvent, direction: number) {
      this.$emit(
          'move-promo-event',
          promoEvent, direction
      )
    },
    removePromoEventFromList(id: string) {
      this.$emit(
          'start-loading'
      );
      this.$emit(
          'remove-promo-event',
          id
      );
    },
    editPromoEvent(value: Alert) {
      this.$emit(
          'edit-promo-event',
          value
      );
    },
    invokeError(message: string) {
      this.$emit(
          'invoke-error',
          message
      );
    },
    invokeSuccess(title: string, message: string) {
      this.$emit(
          'invoke-success',
          title,
          message
      );
    },
  }
});
